// ** Logo
import logo from '@src/assets/images/logo/P8_logo_1.svg'


const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner vh-100'>
      <img className='fallback-logo' src={logo} alt='logo' style={{height:"40px", width:"40px"}}/>
      {/* <img className='fallback-logo' src={'../../assets/images/logo/P8_logo_1.svg'} alt='logo' style={{height:"40px", width:"40px"}}/> */}
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
